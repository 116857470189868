import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
//import './page.css'
import './artists.css'

class Artists extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')    
    const partnerLabel = get(this, 'props.data.partnerLabels.edges')
    const alumniLabel = get(this, 'props.data.alumniLabels.edges')
    const artist = get(this, 'props.data.artists.edges')
    const alumniArtist = get(this, 'props.data.alumniArtists.edges')
    

    return (
      <Layout location={this.props.location}>
        <div className="page artists-page page-wrapper">
          <Helmet title={siteTitle} />
          <div className="grid__item">
            <div className="item__inner partners">
              <h2 className="section-title">Partners</h2>
              <ul>
                {partnerLabel.map(({ node }) => {
                  return (
                    <li key={node.title}>
                      <a href={node.link} target="_blank" rel="noreferrer">{node.title}</a>
                    </li>
                  )
                })}              
              </ul>
            </div>
            <div className="item__inner label-alumni">
              <h2 className="section-title">LABEL ALUMNI</h2>
              <ul>
                {alumniLabel.map(({ node }) => {
                  return (
                    <li key={node.title}>
                      <a href={node.link} target="_blank" rel="noreferrer">{node.title}</a>
                    </li>
                  )
                })}              
              </ul>
            </div>            
          </div>
          <div className="grid__item">
            <div className="item__inner artists">
              <h2 className="section-title">ARTISTS</h2>
              <ul className="grid">
                {artist.map(({ node }) => {
                  return (
                    <li key={node.title}>
                      <a href={node.link} target="_blank" rel="noreferrer">{node.title}</a>
                    </li>
                  )
                })}              
              </ul>
            </div>
            <div className="item__inner alumni-artists">
              <h2 className="section-title">ALUMNI ARTISTS</h2>
              <ul className="grid">
                {alumniArtist.map(({ node }) => {
                  return (
                    <li key={node.title}>
                      <a href={node.link} target="_blank" rel="noreferrer">{node.title}</a>
                    </li>
                  )
                })}              
              </ul>
            </div>            
          </div>          
        </div>
      </Layout>
    )
  }
}

export default Artists

export const pageQuery = graphql`
  query Artists {
    partnerLabels: allContentfulLabel(filter: {tag: {eq: "Partners"}}, sort: {fields: title, order: ASC}) {
      edges {
        node {          
          link                 
          title
        }
      }
    }        
    alumniLabels: allContentfulLabel(filter: {tag: {eq: "Alumni"}}, sort: {fields: title, order: ASC}) {
      edges {
        node {          
          link              
          title
        }
      }
    }
    alumniArtists: allContentfulArtist(filter: {tag: {eq: "Alumni"}}, sort: {fields: title, order: ASC}) {
      edges {
        node {          
          link
          title            
        }
      }
    }
    artists: allContentfulArtist(filter: {tag: {ne: "Alumni"}}, sort: {fields: title, order: ASC}) {
      edges {
        node {          
          link
          title            
        }
      }
    }     
  }
`
